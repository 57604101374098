import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './App.css'; // Импортируем файл стилей

const App = () => {
    const [userId, setUserId] = useState(null);
    const [startParam, setStartParam] = useState(null);
    const [logs, setLogs] = useState([]);
    const [participationStatus, setParticipationStatus] = useState('');
    const [instruction, setInstruction] = useState(''); // Инструкция для пользователя
    const [loading, setLoading] = useState(false); // Индикатор загрузки

    useEffect(() => {
        const script = document.createElement('script');
        script.src = 'https://telegram.org/js/telegram-web-app.js';
        script.async = true;
        document.body.appendChild(script);

        script.onload = async () => {
            if (window.Telegram && window.Telegram.WebApp) {
                const tg = window.Telegram.WebApp;
                tg.expand();

                const initDataUnsafe = tg.initDataUnsafe;
                const telegramId = initDataUnsafe.user?.id.toString();
                setUserId(telegramId);
                addLog(`User ID: ${telegramId}`);
                await checkAndAddUser(telegramId);

                const giveawayId = initDataUnsafe.start_param;
                if (giveawayId) {
                    setStartParam(giveawayId);
                    await checkParticipationStatus(giveawayId, telegramId);
                }
            }
        };

        return () => {
            document.body.removeChild(script);
        };
    }, []);

    const addLog = (message) => {
        setLogs((prevLogs) => [...prevLogs, message]);
    };

    const checkAndAddUser = async (telegramId) => {
        try {
            const response = await axios.get(`http://localhost:3000/users/${telegramId}`);
            if (response.status === 200) {
                addLog(`User ${telegramId} already exists in database with code: ${response.data.code}`);
            }
        } catch (error) {
            if (error.response && error.response.status === 404) {
                const newUser = await axios.post('http://localhost:3000/users', {
                    telegram_id: telegramId,
                    forum: null, // Изначально форум не привязан
                });
                addLog(`User ${telegramId} added to database with code: ${newUser.data.code}`);
            } else {
                console.error('Ошибка при проверке или добавлении пользователя:', error);
                addLog('Ошибка при проверке или добавлении пользователя в базу данных.');
            }
        }
    };

    const checkParticipationStatus = async (giveawayId, telegramId) => {
        try {
            const response = await axios.get(`http://localhost:3000/participants/${giveawayId}/${telegramId}`);
            setParticipationStatus('User is participating');
        } catch (error) {
            if (error.response && error.response.status === 404) {
                setParticipationStatus('User is not participating');
            } else {
                console.error('Ошибка при проверке статуса участия:', error);
                addLog('Ошибка при проверке статуса участия');
            }
        }
    };

    const linkForum = async (userCode) => {
        try {
            const forumResponse = await axios.get(`http://localhost:3000/forum/check/${userCode}`);
            if (forumResponse.status === 200) {
                addLog(`Код проверен на форуме: ${forumResponse.data.userLink}`);
                await axios.put(`http://localhost:3000/users/${userId}/forum`, {
                    forum: forumResponse.data.userLink // Сохраняем ссылку на профиль пользователя на форуме
                });
                addLog(`Форум добавлен для пользователя ${userId}: ${forumResponse.data.userLink}`);
                return true; // Привязка форума прошла успешно
            } else {
                return false; // Код не найден
            }
        } catch (error) {
            console.error('Ошибка при проверке кода на форуме:', error);
            return false; // Произошла ошибка
        }
    };

    const checkIfUserLikedPost = async () => {
        try {
            const response = await axios.get(`http://localhost:3000/forum/check-like/${userId}`);
            return response.data.liked; // Возвращаем результат проверки лайка
        } catch (error) {
            console.error('Ошибка при проверке лайка на форуме:', error);
            return false; // Произошла ошибка
        }
    };

    const checkIfUserCommented = async () => {
        try {
            const response = await axios.get(`http://localhost:3000/forum/check-comment/${userId}`);
            return response.status === 200; // Проверяем, если статус 200, значит пользователь оставил комментарий
        } catch (error) {
            console.error('Ошибка при проверке комментария на форуме:', error);
            return false; // Произошла ошибка, предполагаем, что комментарий не найден
        }
    };

    const participateInGiveaway = async (checkForum = true, checkLike = true,  checkComment = true) => {
        setLoading(true); // Начинаем загрузку
        let userCode; // Переменная для хранения кода пользователя
        try {
            addLog(`Попытка участия в гивэе: ${startParam}, ID пользователя: ${userId}`);

            const userResponse = await axios.get(`http://localhost:3000/users/${userId}`);
            userCode = userResponse.data.code; // Сохраняем код пользователя

            if (checkForum && !userResponse.data.forum) {
                const isForumLinked = await linkForum(userCode); // Проверяем привязку форума
                if (!isForumLinked) {
                    setInstruction(`
                        Вам нужно связать свой форумный и Telegram аккаунт, для этого:
                        <ol>
                            <li>Авторизуйтесь на <a href="https://forum.rpg-club.org/login" target="_blank">форуме</a> под своим аккаунтом. Если у вас нет аккаунта на форуме - зарегистрируйте его.</li>
                            <li>Перейдите по <a href="https://forum.rpg-club.org/account/preferences" target="_blank">ссылке</a> и разместите в поле 'Telegram Event Connect' код: <strong>${userCode}</strong></li>
                            <li>Нажмите "Сохранить"</li>
                            <li>После выполнения действий нажмите кнопку "Участвовать" еще раз.</li>
                        </ol>
                    `);
                    return;
                }
            }

            if (checkLike) {
                const isLiked = await checkIfUserLikedPost(); // Проверяем, поставил ли пользователь лайк
                if (!isLiked) {
                    setInstruction(`Для участия в розыгрыше вам необходимо поставить лайк на форуме по <a href="https://forum.rpg-club.org/threads/event-squish-your-squash.137789/#post-1832290" target="_blank">ссылке</a>`);
                    return;
                }
            }

            if (checkComment) {
                const hasCommented = await checkIfUserCommented(); // Проверяем, оставил ли пользователь комментарий
                if (!hasCommented) {
                    setInstruction(`Для участия в розыгрыше, напишите ваше мнение в теме на <a href="https://forum.rpg-club.org/threads/event-squish-your-squash.137789/#post-1832290" target="_blank">форуме</a> <div style="color: red">Неконструктивные посты направленны на абуз участия будут удалены, а участник исключен из розыгрыша</div>`);
                    return;
                }
            }

            // Теперь пользователь может участвовать в гивэе
            const response = await axios.post('http://localhost:3000/participants', {
                giveaway_id: startParam,
                telegram_id: userId,
            });
            addLog(`User ${userId} added to giveaway ${startParam}.`);
            setParticipationStatus('User is participating');

            // Сбрасываем инструкцию после успешного участия
            setInstruction('');
        } catch (error) {
            console.error('Ошибка при добавлении участника в гивэй:', error.response ? error.response.data : error.message);
            addLog('Ошибка при добавлении участника в гивэй: ' + (error.response ? error.response.data : error.message));
            setInstruction('Произошла ошибка при попытке участия в гивэе. Попробуйте еще раз.');
        } finally {
            setLoading(false); // Завершаем загрузку
        }
    };

    return (
        <div className="app-container">
            <h1>Добро пожаловать в наше мини-приложение!</h1>
            {userId ? <p>Ваш ID: {userId}</p> : <p>ID пользователя не найден.</p>}
            {startParam ? <p>start_param: {startParam}</p> : <p>start_param не найден.</p>}
            <h2>Статус участия в гивэе:</h2>
            <p>{participationStatus}</p>
            {participationStatus === 'User is not participating' && (
                <div>
                    <button onClick={() => participateInGiveaway(true, true, true)} disabled={loading}>
                        {loading ? 'Загрузка...' : 'Участвовать'}
                    </button>
                </div>
            )}
            {instruction && (
                <div className="instruction" dangerouslySetInnerHTML={{ __html: instruction }} />
            )}
            <h2>Логи:</h2>
            <ul className="logs">
                {logs.map((log, index) => (
                    <li key={index}>{log}</li>
                ))}
            </ul>
        </div>
    );
};

export default App;
